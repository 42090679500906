<template>
  <div>
    <div class="flex flex-col w-full min-h-screen relative bg-white">
      <Header :page="{ isHome: false }" />

      <div
        v-if="event"
        class="container grid-main"
      >
        <TitleWithBackButton
          class="col-span-12 mt-6"
          :title="event.title"
          :backLabel="'zurück zur Veranstaltungsübersicht'"
          :back-url="eventPageFullPath || '/'"
        />

        <!-- Event image -->
        <img
          class="col-span-12 max-h-[calc(100vh-(theme(grid.gridXMargin)))] object-cover"
          :src="event.images.list[0].publicUrlForCrop"
          :width="event.images.list[0].properties.dimensions.width"
          :height="event.images.list[0].properties.dimensions.height"
        />

        <!-- Start date & Start time -->
        <div class="col-span-12 border-b-[0.5px] py-8 border-[#93A1AF]">
          <div class="flex items-center justify-between">
            <p>{{ $text('event-detail-page-date') }}</p>
            <p>{{ convertDateToEuropeanFormat(startDate as string) }}, {{ startTime }}</p>
          </div>
        </div>

        <!-- Summary -->
        <div class="col-span-12 grid-main py-8 border-b-[0.5px] border-[#93A1AF]">
          <p class="paragraph-1 mb-6 col-span-9 lead">{{ event.summary }}</p>
          <div
            class="col-span-12 lg:col-span-9 markdown-render"
            v-html="$mdRenderer.render(event.description)"
          />
        </div>

        <!-- Price -->
        <div
          v-if="event.prices[0]?.labelPrice"
          class="col-span-12"
        >
          <div class="flex border-b-[0.5px] py-8 border-[#93A1AF]">
            <p class="w-1/2 lg:w-[500px] mr-2">{{ $text('event-detail-page-price') }}</p>

            <div>
              <p>{{ event.prices[0]?.labelPrice }}</p>
            </div>
          </div>
        </div>

        <!-- Info location -->
        <div
          v-if="event.infoLocation"
          class="col-span-12"
        >
          <div class="flex py-8">
            <p class="w-1/2 lg:w-[500px] mr-2">{{ $text('event-detail-page-info-location') }}</p>

            <div>
              <p>{{ event.infoLocation }}</p>
              <NuxtLink
                v-if="event?.otherCoordinates?.latitude && event?.otherCoordinates?.longitude"
                target="_BLANK"
                :to="`https://www.google.com/maps?q=${event.otherCoordinates.latitude},${event.otherCoordinates.longitude}`"
              >
                <ArrowLink
                  class="arrowLink"
                  :text="$text('google-directions-button') || 'Google Maps'"
                  :tag="'p'"
              /></NuxtLink>
            </div>
          </div>
          <div class="w-full border-b-[0.5px] border-[#93A1AF]" />
        </div>

        <!-- Info contact -->
        <div
          v-if="event.infoContact"
          class="col-span-12"
        >
          <div class="flex py-8">
            <p class="w-1/2 lg:w-[500px] mr-2">{{ $text('event-detail-page-contact') }}</p>

            <div class="flex flex-col">
              <p class="font-bold">{{ event.infoContact?.company }}</p>
              <p v-if="event.infoContact.region">{{ event.infoContact.region }}</p>
              <a
                :href="`tel:${event.infoContact.phone}`"
                v-if="event.infoContact.phone"
                >{{ event.infoContact.phone }}</a
              >
              <a
                :href="`tel:${event.infoContact.mobile}`"
                v-if="event.infoContact.mobile"
                >{{ event.infoContact.mobile }}</a
              >
              <a
                v-if="event.bookingContact.email"
                class="cursor-pointer w-full"
                :href="`mailto:${event.bookingContact.email}`"
                >{{ event.bookingContact.email }}</a
              >

              <a
                v-if="event.bookingContact.website"
                target="_BLANK"
                class="cursor-pointer w-full"
                :href="`${event.bookingContact.website}`"
                >{{ event.bookingContact.website }}</a
              >
            </div>
          </div>
          <div class="w-full border-b-[0.5px] border-[#93A1AF]" />
        </div>
        <!-- Default Waldhaus location -->
        <div
          class="col-span-12"
          v-else
        >
          <div class="flex items-center py-8">
            <p class="w-1/2 lg:w-[500px] mr-2">{{ $text('event-detail-page-contact') }}</p>

            <div class="waldhaus-contact flex flex-col">
              <SanityContent :blocks="siteParts?.contactInfo?.contactText" />
              <!-- <a :href="`${siteParts?.contactInfo.mailUs.externalLink}`">
              {{ siteParts?.contactInfo.mailUs.label }}</a
            >
            <a
              v-for="(item, index) in siteParts?.contactInfo.contactButtonList"
              :href="item.button.externalLink"
              :key="index"
            >
              {{ item.label }}</a
            > -->
            </div>
          </div>
          <div class="w-full border-b-[0.5px] border-[#93A1AF]" />
        </div>
      </div>

      <!-- Fallback if anything goes wrong with the external API -->
      <div
        class="container mt-8"
        v-else
      >
        <TitleWithBackButton
          class="mt-6"
          :title="''"
          :backLabel="'zurück zur Veranstaltungsübersicht'"
          :back-url="eventPageFullPath || '/'"
        />
        <h3 class="mb-2">Attention!</h3>
        <p>There was an issue with our Event System API. We are invastigating on the issue.</p>
        <pre>page: {{ event }}</pre>
        <pre>event: {{ event }}</pre>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
import { ContentItem } from 'types';

// Props
interface EventPageProps {
  page: {
    _type: string;
    event: ContentItem;
  };
}
const { page } = defineProps<EventPageProps>();

// Stores
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);

// Composables
const { convertDateToEuropeanFormat } = useSiteSettings();
const { $fullpath, $text } = useSiteSettings();
const {
  query: { startDate, startTime }
} = useRoute();

// Refs
const event = page.event?.data?.item;
const eventPageFullPath = siteParts.value && $fullpath(siteParts.value?.general?.eventPage?._ref);
</script>

<style lang="postcss">
.waldhaus-contact {
  p {
    @apply paragraph-1 text-black not-italic;
    white-space: pre-line;
  }
}
.markdown-render {
  p:not(:first-child),
  p * {
    @apply py-1;
    font-size: fluid(18, 18);
    line-height: fluid(23.8, 23.8);
  }
  p:first-child {
    @apply mb-4;
  }
  p {
    /* @apply border-b-[0.2px] border-[#93A1AF]; */
  }
}
</style>
